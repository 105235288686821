import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";
import { NOTE_STATE } from "./constants";

const INITIAL_STATE = Immutable.Map({
  sounds: Immutable.List()
});

const reducer = handleActions(
  {
    [Types.LOAD_NOTE]: (state, action) => {
      const sounds = state.get("sounds").push(
        Immutable.Map({
          id: action.payload,
          state: NOTE_STATE.NOT_PLAYED,
          muted: false,
          sound: null
        })
      );

      return state.updateIn(["sounds"], () => sounds);
    },
    [Types.PLAY_NOTE_START]: (state, action) => {
      const index = state
        .get("sounds")
        .findIndex(sound => sound.get("id") === action.payload);

      const sounds = state
        .get("sounds")
        .setIn([index, "id"], action.payload)
        .setIn([index, "state"], NOTE_STATE.LOADING);

      return state.updateIn(["sounds"], () => sounds);
    },

    [Types.PLAY_NOTE_SUCCESS]: (state, action) => {
      const index = state
        .get("sounds")
        .findIndex(sound => sound.get("id") === action.payload.get("id"));

      const sounds = state
        .get("sounds")
        .setIn([index, "state"], NOTE_STATE.PLAYING)
        .setIn([index, "sound"], action.payload.get("sound"));

      return state.updateIn(["sounds"], () => sounds);
    },

    [Types.PAUSE_NOTE_SUCCESS]: (state, action) => {
      const index = state
        .get("sounds")
        .findIndex(sound => sound.get("id") === action.payload.get("id"));
      const sounds = state
        .get("sounds")
        .setIn([index, "state"], NOTE_STATE.PAUSED);

      return state.updateIn(["sounds"], () => sounds);
    },

    [Types.SEEK_NOTE_SUCCESS]: (state, action) => {
      const index = state
        .get("sounds")
        .findIndex(sound => sound.get("id") === action.payload.get("id"));

      const sounds = state
        .get("sounds")
        .setIn([index, "sound"], action.payload.get("sound"))
        .setIn([index, "time", new Date().getTime()]);

      return state.updateIn(["sounds"], () => sounds);
    },

    [Types.STOP_NOTE_SUCCESS]: (state, action) => {
      const index = state
        .get("sounds")
        .findIndex(sound => sound.get("id") === action.payload);
      const sounds = state
        .get("sounds")
        .setIn([index, "state"], NOTE_STATE.STOPPED);

      return state.updateIn(["sounds"], () => sounds);
    },
    [Types.MUTE_NOTE_SUCCESS]: (state, action) => {
      const index = state
        .get("sounds")
        .findIndex(sound => sound.get("id") === action.payload.get("id"));

      const sounds = state
        .get("sounds")
        .setIn([index, "muted"], !action.payload.get("muted"));

      return state.updateIn(["sounds"], () => sounds);
    }
  },
  INITIAL_STATE
);

export default reducer;
