import React from "react";

import queryString from "query-string";

import Feed from "../feed";

function Category(props) {
  // const categories = {
  //   NEKAT: "theater_comedy",
  //   ALFAZ: "translate",
  //   ZA3RANET: "warning",
  //   AGHENE: "music_note"
  // };

  // const category = queryString.parse(props.location.search).category;
  const filter = {
    bool: {
      should: {
        term: {
          categories: queryString.parse(props.location.search).category
        }
      }
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {/*      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum}>
      <Link
        color="inherit"
        href="/explore"
        className={classes.link}
      >
        <WhatshotIcon className={classes.icon} />
        Categories
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        <Icon className={classes.icon}>{categories[category]}</Icon>
        {category}
      </Typography>
    </Breadcrumbs>*/}
      <Feed term="" filter={filter} sort="playCount" />
    </div>
  );
}

export default Category;
