import React from "react";
import Immutable from "immutable";

import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsappIcon from "@material-ui/icons/WhatsApp";

export const Sections = Immutable.Map({
  Whatsapp: {
    name: "WhatsApp",
    icon: <WhatsappIcon style={{ fontSize: 60 }} color="primary" />,
    href: "https://api.whatsapp.com/send?phone=15617821661",
    redirect: true
  },
  Instagram: {
    name: "Instagram",
    icon: <InstagramIcon style={{ fontSize: 60 }} color="primary" />,
    href: "https://www.instagram.com/b3at.co/",
    redirect: true
  },
  Tiktok: {
    name: "TikTok",
    icon: "tiktok",
    href: "https://www.tiktok.com/@b3atco",
    redirect: true
  },
  Email: {
    name: "Email",
    icon: "mail",
    href: "mailto:b3at@b3at.co",
    redirect: true
  }
});

export default Sections;
