import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "space-around",
    padding: 10,
    overflow: "hidden",
    width: "100%",
    overflowY: "scroll"
  }
}));

export default useStyles;
