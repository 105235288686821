import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    overflowY: "scroll",
    height: "100%",
    width: "100%"
  },
  error: {
    minWidth: 400,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },
  autoscroll: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll"
  },
  progress: {
    marginTop: "2%",
    marginBottom: "3%"
  },
  empty: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    fontWeight: "bold",
    fontFamily: "'Acme', sans-serif;",
    marginTop: 20
  },
  banner: {
    backgroundColor: "#c62828",
    padding: 10,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    cursor: "pointer"
  }
}));

export default useStyles;
