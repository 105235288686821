import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 10,
    alignItems: "center"
  },
  comment: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginLeft: 10
  },
  metadata: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

export default useStyles;
