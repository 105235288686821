import Immutable from "immutable";

export const Sections = Immutable.Map({
  Newest: {
    name: "Jdid Jdid",
    icon: "update",
    href: "/newest"
  },
  Hottest: {
    name: "Wel3ane",
    icon: "whatshot",
    href: "/top"
  },
  NSFW: {
    name: "Za3ranet",
    icon: "gpp_bad",
    href: "/category?category=za3ranet"
  },
  Music: {
    name: "Aghene",
    icon: "music_note",
    href: "/category?category=aghene"
  },
  Jokes: {
    name: "Nekat",
    icon: "theater_comedy",
    href: "/category?category=nekat"
  },
  Pronounciations: {
    name: "Alfaz",
    icon: "translate",
    href: "/category?category=alfaz"
  }
});

export default Sections;
