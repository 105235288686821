import { createActions } from "redux-actions";

import Elastic from "../../provider/elastic";

const Types = {
  SUGGEST_START: "SUGGEST_START",
  SUGGEST_SUCCESS: "SUGGEST_SUCCESS",
  SUGGEST_ERROR: "SUGGEST_ERROR"
};

const { suggestStart, suggestSuccess, suggestError } = createActions(
  Types.SUGGEST_START,
  Types.SUGGEST_SUCCESS,
  Types.SUGGEST_ERROR
);

const suggest = (text, filter, sort) => dispatch => {
  dispatch(suggestStart());
  const size = 10;
  const offset = 0;

  new Elastic().search(
    text,
    filter,
    sort,
    size,
    offset,
    (error, response, body) => {
      if (error || response.statusCode !== 200) {
        const message =
          "We're unable to suggest any keywords. Something went wrong";
        return dispatch(suggestError(message));
      }
      return dispatch(suggestSuccess(body.docs));
    }
  );
};

export { Types, suggest };
