import randomColor from "randomcolor";

const Elastic = function() {};

Elastic.prototype.search = function mock(
  search,
  filter,
  sort,
  size,
  offset,
  callback
) {
  const body = [
    {
      id: 0,
      title: "Ne venez plus a l'ecole ici ya pas d'ecole madame",
      description: "Some description",
      tags: ["kesse", "sa2ib"],
      thumbnail:
        "https://ww2.kqed.org/app/uploads/sites/2/2019/08/dora-the-explorer-800x450.jpg",
      path: "unprocessed/AUDIO-2019-11-19-10-33-39.m4a",
      playCount: 1000,
      categories: ["ALFAZ"],
      comments: [
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        }
      ]
    },
    {
      id: 1,
      title: "N'allez plus immaginer",
      description: "Some description",
      tags: ["kesse", "sa2ib"],
      thumbnail:
        "https://ww2.kqed.org/app/uploads/sites/2/2019/08/dora-the-explorer-800x450.jpg",
      path: "unprocessed/AUDIO-2019-11-19-10-33-39.m4a",
      playCount: 1000000,
      categories: ["NEKAT", "ALFAZ"],
      comments: [
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        }
      ]
    },
    {
      id: 2,
      title: "La min kesse ana",
      description: "Some description",
      tags: ["kesse", "sa2ib"],
      thumbnail:
        "https://ww2.kqed.org/app/uploads/sites/2/2019/08/dora-the-explorer-800x450.jpg",
      path: "unprocessed/AUDIO-2019-11-19-10-33-39.m4a",
      playCount: 10,
      comments: [
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        }
      ]
    }
  ];
  const response = {
    statusCode: 200
  };

  setTimeout(() => {
    callback(null, response, { docs: body, total: body.length });
  }, 1000);
};

Elastic.prototype.get = function get(id, callback) {
  const body = [
    {
      id: 2,
      title: "La min kesse ana",
      description: "Some description",
      tags: ["kesse", "sa2ib"],
      thumbnail:
        "https://ww2.kqed.org/app/uploads/sites/2/2019/08/dora-the-explorer-800x450.jpg",
      path: "unprocessed/AUDIO-2019-11-19-10-33-39.m4a",
      playCount: 10000,
      comments: [
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        },
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        },
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        },
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        },
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        },
        {
          timestamp: 1606972770930,
          username: "jellabing",
          color: randomColor(),
          comment: "C'est quoi ce truc de bite bro"
        }
      ]
    }
  ];

  const response = {
    statusCode: 200
  };

  setTimeout(() => {
    callback(null, response, { docs: body, total: 1 });
  }, 1000);
};

Elastic.prototype.suggest = function(text, callback) {
  const suggestions = ["ma3ak", "mami", "mafi", "antoine", "kesse", "bedde"];
  const response = {
    statusCode: 200
  };

  setTimeout(() => {
    callback(null, response, JSON.stringify(suggestions));
  }, 1000);
};

Elastic.prototype.bumpUpPlayCount = function bumpPlayCount(id, callback) {
  const response = {
    statusCode: 200
  };

  callback(null, response, {});
};

Elastic.prototype.getKeywords = function getKeywords(callback) {
  const suggestions = ["ma3ak", "mami", "mafi", "antoine", "kesse", "bedde"];
  const response = {
    statusCode: 200
  };

  setTimeout(() => {
    callback(null, response, JSON.stringify({ docs: suggestions }));
  }, 1000);
};

Elastic.prototype.addComment = function addComment(id, comment, callback) {
  const response = {
    statusCode: 200
  };

  callback(null, response, {});
};

export default Elastic;
