import React from "react";

import queryString from "query-string";

import Feed from "../feed";

function Search(props) {
  return <Feed term={queryString.parse(props.location.search).search} />;
}

export default Search;
