import React from "react";

import Feed from "../feed";

function Home(props) {
  return (
    <Feed
      term=""
      filter=""
      sort="_id"
      randomize
    />
  );
}

export default Home;
