import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import numeral from "numeral";
import Badge from "@material-ui/core/Badge";

import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Replay5Icon from "@material-ui/icons/Replay5";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Forward5Icon from "@material-ui/icons/Forward5";
import CircularProgress from "@material-ui/core/CircularProgress";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import Icon from "@material-ui/core/Icon";
import ReplayIcon from "@material-ui/icons/Replay";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import Progress from "./progress";
import Social from "./share";

import styles from "./style";
import { NOTE_STATE } from "../../stores/sounds/constants";
import {
  loadNote,
  playNote,
  pauseNote,
  stopNote,
  seekNote,
  muteNote
} from "../../stores/sounds/actions";
import { addFavorite, removeFavorite } from "../../stores/favorites/actions";

const categories = {
  NEKAT: "theater_comedy",
  ALFAZ: "translate",
  ZA3RANET: "gpp_bad",
  AGHENE: "music_note"
};

const AudioCard = function(props) {
  const PlayControl = function(props) {
    const classes = styles();

    const icon = (state => {
      switch (state) {
        case NOTE_STATE.LOADING:
          return (
            <CircularProgress
              className={classes.playIcon}
              disableShrink
              size={40}
              color="primary"
            />
          );
        case NOTE_STATE.PLAYING:
          return (
            <PauseIcon className={classes.playIcon} style={{ fontSize: 40 }} />
          );
        default:
          return (
            <PlayArrowIcon
              className={classes.playIcon}
              style={{ fontSize: 40 }}
            />
          );
      }
    })(props.state);

    const action = (state => {
      switch (state) {
        case NOTE_STATE.PLAYING:
          return props.onPause;
        default:
          return props.onPlay;
      }
    })(props.state);

    return (
      <Fab color="primary" size="large" aria-label="play" onClick={action}>
        {icon}
      </Fab>
    );
  };

  const classes = styles();

  const [share, setShare] = useState(false);

  useEffect(() => {
    props.loadNote(props.note.get("id"));
  }, []);

  const sound = props.sounds.find(
    sound => sound.get("id") === props.note.get("id")
  );

  const getPlayCount = count => {
    if (count > 1000) {
      return numeral(count).format("0.0a");
    }
    return numeral(count).format("0a");
  };

  const isMuted = () => {
    return sound && sound.get("muted");
  };

  const isFavorite = () => {
    return props.favorites.has(props.note.get("id"));
  };

  const onSeek = (note, position) => {
    if (sound && sound.get("sound")) {
      const sec = (position / 100) * sound.get("sound").duration();
      props.seekNote(note, sec);
    }
  };

  const onRewind = note => {
    if (sound && sound.get("sound")) {
      const seek = Math.max(0, sound.get("sound").seek() - 5);
      props.seekNote(note, seek);
    }
  };

  const onForward = note => {
    if (sound && sound.get("sound")) {
      const seek = Math.min(
        sound.get("sound").seek() + 5,
        sound.get("sound").duration()
      );
      props.seekNote(note, seek);
    }
  };

  const comments = props.note.get("comments")
    ? props.note.get("comments").size
    : 0;

  return (
    <React.Fragment>
      <Paper elevation={4} raised className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <div className={classes.stats}>
              <div>
                {(props.note.get("categories") || []).map(c => (
                  <Icon
                    style={{ fontSize: 20, marginRight: 5 }}
                    color="primary">
                    {categories[c]}
                  </Icon>
                ))}
              </div>
              <div className={classes.playCount}>
                <PlayArrowIcon />
                <Typography variant="button" display="block">
                  {getPlayCount(props.note.get("playCount"))}
                </Typography>
              </div>
            </div>
            <Typography className={classes.title} component="h6" variant="h6">
              {props.note.get("title")}
            </Typography>
          </CardContent>
          <div className={classes.controls}>
            <div className={classes.radioButtons}>
              <Fab
                size="small"
                className={classes.control}
                onClick={() => onRewind(props.note)}>
                <Replay5Icon />
              </Fab>
              <PlayControl
                className={classes.controls}
                state={sound ? sound.get("state") : NOTE_STATE.NOT_PLAYED}
                onPlay={() => props.playNote(props.note)}
                onPause={() => props.pauseNote(props.note)}
              />
              <Fab
                size="small"
                className={classes.control}
                onClick={() => onForward(props.note)}>
                <Forward5Icon />
              </Fab>
            </div>
            <Progress sound={sound} note={props.note} onSeek={onSeek} />
            <div className={classes.actions}>
              <IconButton
                onClick={
                  isFavorite()
                    ? () => props.removeFavorite(props.note.get("id"))
                    : () => props.addFavorite(props.note.get("id"))
                }
                aria-label="next">
                {isFavorite() ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
              <IconButton onClick={() => setShare(true)} aria-label="next">
                <ShareIcon />
              </IconButton>
              <IconButton
                onClick={() => onSeek(props.note, 0)}
                aria-label="next">
                <ReplayIcon />
              </IconButton>
              <IconButton
                onClick={() => props.muteNote(props.note)}
                aria-label="next">
                {isMuted() ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </IconButton>
              <IconButton
                onClick={() =>
                  props.history.push(props.note.get("id").toString())
                }
                aria-label="next">
                {comments !== 0 && (
                  <Badge color="primary" badgeContent={comments}>
                    <ChatBubbleIcon />
                  </Badge>
                )}
                {comments === 0 && <ChatBubbleIcon />}
              </IconButton>
            </div>
          </div>
        </div>
        <Social
          open={share}
          url={process.env.REACT_APP_APP_DOMAIN + props.note.get("id")}
          title={props.note.get("path")}
          onClose={() => setShare(false)}
        />
      </Paper>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    sounds: state.SoundsStore.get("sounds"),
    favorites: state.FavoritesStore.get("favorites")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadNote: id => dispatch(loadNote(id)),
    playNote: note => dispatch(playNote(note)),
    stopNote: note => dispatch(stopNote(note)),
    seekNote: (note, position) => dispatch(seekNote(note, position)),
    pauseNote: note => dispatch(pauseNote(note)),
    muteNote: note => dispatch(muteNote(note)),
    addFavorite: id => dispatch(addFavorite(id)),
    removeFavorite: id => dispatch(removeFavorite(id))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AudioCard));
