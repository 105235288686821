import React from "react";

import Typography from "@material-ui/core/Typography";

import styles from "./style";

function Empty(props) {
  const classes = styles();

  return (
    <div className={classes.root}>
      <img src="assets/empty_404.png" alt="404 not found" width="250" />
      <div className={classes.actions}>
        <Typography component="h4" variant="h4" className={classes.title}>
          Oups
        </Typography>
        <Typography component="h6" variant="h6" className={classes.title}>
          We're not sure how you ended up here
        </Typography>
      </div>
    </div>
  );
}

export default Empty;
