import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

import styles from "./style";

const PrettoSlider = withStyles(styles)(function(props) {
  return <Slider {...props} />;
});

const Elapsed = function(props) {
  const { classes } = props;

  //Used to force update every second
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    //Set interval to re-render component every 0.5 seconds
    const interval = setInterval(() => setTime(new Date()), 100);
    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  const getSliderValue = () => {
    if (!props.sound || !props.sound.get("sound")) return 0;
    return (
      (props.sound.get("sound").seek() / props.sound.get("sound").duration()) *
      100
    );
  };

  const getTime = time => {
    let seek = 0;
    if (props.sound && props.sound.get("sound")) seek = time();

    const duration = moment.duration(seek, "seconds");
    const mins = duration
      .minutes()
      .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
    const secs = duration
      .seconds()
      .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

    return `${mins}:${secs}`;
  };

  return (
    <div className={classes.seek}>
      <PrettoSlider
        className={classes.slider}
        valueLabelDisplay="off"
        value={getSliderValue()}
        onChange={(e, v) => props.onSeek(props.note, v)}
      />
      <div className={classes.times}>
        <Typography className={classes.time} variant="subtitle2">
          {getTime(() => props.sound.get("sound").seek())}
        </Typography>
        <Typography className={classes.time} variant="subtitle2">
          {getTime(() => props.sound.get("sound").duration())}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(Elapsed);
