import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";

import { suggest } from "../../stores/suggestions/actions";

import styles from "./style";

function SearchBar(props) {
  const classes = styles();

  //We do not want to filter on result on the UI
  const filterOptions = (options, state) => options;

  const getInputProps = params => {
    const inputProps = {
      ...params.InputProps,
      classes: classes,
      style: { color: "white" },
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon style={{ color: "white" }} size={25} />
        </InputAdornment>
      )
    };

    if (props.loading) {
      return {
        ...inputProps,
        endAdornment: (
          <InputAdornment position="start">
            <CircularProgress style={{ color: "white" }} size={20} />
          </InputAdornment>
        )
      };
    }

    return { ...inputProps, endAdornment: params.InputProps.endAdornment };
  };

  const onKeyDown = (e, v) => {
    if (e.keyCode === 13) {
      e.stopPropagation();
      console.log(e.target.value);
      props.history.push("/search?search=" + e.target.value);
    }
  };

  return (
    <Autocomplete
      style={{ width: "100%" }}
      freeSolo
      disableClearable
      options={props.suggestions.toJS()}
      filterOptions={filterOptions}
      onChange={(e, option) =>
        window.open(
          `${process.env.REACT_APP_API_ENDPOINT}/${option.id}`,
          "_self"
        )
      }
      renderOption={option => (
        <div className={classes.option}>
          <div style={{ fontSize: 14 }}>{option.title}</div>
        </div>
      )}
      renderInput={params => (
        <div className={classes.search}>
          <TextField
            {...params}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            onChange={e => props.suggest(e.target.value)}
            onKeyDown={e => onKeyDown(e, params.inputProps.value)}
            InputProps={getInputProps(params)}
          />
        </div>
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    loading: state.SuggestionsStore.get("loading"),
    error: state.SuggestionsStore.get("error"),
    suggestions: state.SuggestionsStore.get("suggestions")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    suggest: text => dispatch(suggest(text))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchBar));
