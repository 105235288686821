import React from "react";
import moment from "moment";

import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import styles from "./style";

export default function Comment(props) {
  const classes = styles();

  const duration = moment
    .duration(Date.now() - props.comment.get("timestamp"))
    .humanize();

  return (
    <Paper elevation={5} className={classes.root}>
      <Avatar style={{ backgroundColor: props.comment.get("color") }}>
        {props.comment
          .get("username")
          .charAt(0)
          .toUpperCase()}
      </Avatar>
      <div className={classes.comment}>
        <div className={classes.metadata}>
          <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
            {props.comment.get("username")}
          </Typography>
          <Typography variant="caption">{`${duration} ago`}</Typography>
        </div>
        <Typography variant="subtitle2">
          {props.comment.get("comment")}
        </Typography>
      </div>
    </Paper>
  );
}
