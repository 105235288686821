import { createActions } from "redux-actions";

const Types = {
  ADD_FAVORITE: "ADD_FAVORITE",
  REMOVE_FAVORITE: "REMOVE_FAVORITE"
};

const { addFavorite, removeFavorite } = createActions(
  Types.ADD_FAVORITE,
  Types.REMOVE_FAVORITE
);

export { Types, addFavorite, removeFavorite };
