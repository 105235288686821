import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import ScaleLoader from "react-spinners/ScaleLoader";
import randomColor from "randomcolor";

import AudioCard from "../audio";
import Empty from "../empty";
import Comment from "./comment";
import Banner from "../ads";

import { clearError, get, addComment } from "../../stores/notes/actions";
import { keywords } from "../../stores/keywords/actions";

import styles from "./style";

function Single(props) {
  const classes = styles();

  const onAddComment = () => {
    const rand1 = Math.floor(Math.random() * Math.floor(props.keywords.size));
    const rand2 = Math.floor(Math.random() * Math.floor(props.keywords.size));
    const username = `${props.keywords.get(rand1)}_${props.keywords.get(
      rand2
    )}`;

    const toAdd = {
      timestamp: Date.now(),
      username: username,
      color: randomColor(),
      comment: comment
    };
    props.addComment(id, toAdd);
    setComment("");
  };

  useEffect(() => {
    props.get([props.match.params.id]);
    props.getKeywords();
  }, []);

  const [comment, setComment] = useState("");

  const spinner = (
    <ScaleLoader
      css={
        "display: flex; justify-content: center; align-items: center; flex-grow: 2"
      }
      size={2000}
      color={"#d32f2f"}
      loading={props.loading}
    />
  );

  const comments = props.notes.flatMap(n => n.get("comments"));
  const id = props.notes.map(n => n.get("id")).first();

  const grid =
    props.notes.size === 0 ? (
      <Empty />
    ) : (
      <div className={classes.parent}>
        <Grid style={{ marginBottom: 70 }} container className={classes.root}>
          <Grid
            item
            xs={12}
            style={{ padding: 20 }}
            >
            <Banner />
          </Grid>
          {props.notes.map(note => {
            return (
              <Grid
                key={note.get("id")}
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={3}>
                <AudioCard note={note} />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
            {comments.map(comment => {
              return (
                <Grid item xs={12}>
                  <Comment comment={comment} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          style={{ backgroundColor: "white", zIndex: 1 }}
          container
          className={classes.post}>
          <Grid
            className={classes.input}
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={3}>
            <TextField
              value={comment}
              className={classes.text}
              placeholder="Comment"
              id="filled-margin-dense"
              margin="dense"
              variant="outlined"
              fullWidth
              onChange={e => setComment(e.target.value)}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  onAddComment();
                }
              }}
            />
            <IconButton
              disabled={comment === ""}
              color="primary"
              onClick={() => onAddComment()}>
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );

  return <React.Fragment>{props.loading ? spinner : grid}</React.Fragment>;
}

function mapStateToProps(state) {
  return {
    loading: state.LatestStore.get("loading"),
    error: state.LatestStore.get("error"),
    notes: state.LatestStore.get("notes"),
    keywords: state.KeywordsStore.get("keywords")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get: ids => dispatch(get(ids)),
    getKeywords: () => dispatch(keywords()),
    addComment: (id, comment) => dispatch(addComment(id, comment)),
    clearError: () => dispatch(clearError())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Single);
