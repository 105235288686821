import React from "react";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { withRouter } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
import FavoriteIcon from "@material-ui/icons/Favorite";
import WhatsappIcon from "@material-ui/icons/WhatsApp";

import SearchBar from "../searchBar";

import styles from "./style";

const Bar = function(props) {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const pages = [
    {
      id: "home",
      name: "Feed",
      icon: <HomeIcon />,
      url: process.env.REACT_APP_API_ENDPOINT
    },
    {
      id: "explore",
      name: "Categories",
      icon: <SearchIcon />,
      url: `${process.env.REACT_APP_API_ENDPOINT}/explore`
    },
    {
      id: "favorites",
      name: "Favorites",
      icon: <FavoriteIcon />,
      url: `${process.env.REACT_APP_API_ENDPOINT}/favorites`
    },
    {
      id: "shop",
      name: "Shop",
      icon: <ShoppingCartIcon />,
      url: "https://shop.b3at.co"
    },
    {
      id: "contact",
      name: "Contact",
      icon: <MailIcon />,
      url: `${process.env.REACT_APP_API_ENDPOINT}/contact`
    }
  ];

  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar disableGutters className={classes.bar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}>
            <a>
              <img src="assets/logo.png" width="50" />
            </a>
          </IconButton>
          <SearchBar />
          <div className={classes.links}>
            {/*            <IconButton
              className={classes.accountButton}
              color="inherit"
              onClick={e => setAnchorEl(e.currentTarget)}>
              <InfoIcon />
            </IconButton>*/}
            <a
              className={classes.accountButton}
              target="blank"
              href="https://api.whatsapp.com/send?phone=15617821661">
              <WhatsappIcon color="secondary" style={{ margin: 5 }} />
            </a>
          </div>
          <Popover
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}>
            <div className={classes.info}>
              <Typography variant="subtitle2">
                Eza fi chi na2is, b3atoulna yehon 3a{" "}
                <a href="mailto:b3at@b3at.co">b3at@b3at.co</a>
              </Typography>
            </div>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <List>
          {pages.map(page => (
            <ListItem
              onClick={() => window.open(page.url, "_self")}
              button
              key={page.id}>
              <ListItemIcon className={classes.red} color="primary">
                {page.icon}
              </ListItemIcon>
              <ListItemText color="primary" primary={page.name} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      {props.children}
    </React.Fragment>
  );
};

export default withRouter(Bar);
