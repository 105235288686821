import React from "react";
import { connect } from "react-redux";

import Feed from "../feed";

import { get, getNextPage } from "../../stores/notes/actions";

function Favorites(props) {
  const onSearch = ids => props.get(ids);
  const onNextPage = ids => props.getNextPage(ids);

  return (
    <Feed
      sort="_id"
      onSearch={() => onSearch(props.favorites)}
      onNextPage={() => onNextPage(props.favorites)}
    />
  );
}

function mapStateToProps(state) {
  return {
    favorites: state.FavoritesStore.get("favorites")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get: ids => dispatch(get(ids)),
    getNextPage: ids => dispatch(getNextPage(ids))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
