import React, { useEffect } from "react";
import { connect } from "react-redux";

import { withSnackbar } from "notistack";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import ScaleLoader from "react-spinners/ScaleLoader";
import Typography from "@material-ui/core/Typography";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import Icon from "@material-ui/core/Icon";

import InfiniteScroll from "react-infinite-scroll-component";
import Banner from "../ads";
import AudioCard from "../audio";

import { clearError, search, nextPage } from "../../stores/notes/actions";

import styles from "./style";

function Feed(props) {
  const classes = styles();

  //methods overrides
  const onSearch = props.onSearch ? props.onSearch : props.search;
  const onNextPage = props.onNextPage ? props.onNextPage : props.nextPage;

  useEffect(() => {
    onSearch(props.term, props.filter, props.sort);
  }, [props.term]);

  const showError = error => {
    props.enqueueSnackbar(error, {
      variant: "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
      }
    });
  };

  const { offset, total, error } = props;
  const notes = props.randomize
    ? props.notes.sort(() => Math.random() - 0.5)
    : props.notes;

  const loadingSpinner = (
    <CircularProgress className={classes.progress} color="primary" size={40} />
  );

  const empty = (
    <div className={classes.empty}>
      <img src="assets/empty.png" width="200" />
      <Typography className={classes.title} variant="h5">
        Nothing found here
      </Typography>
    </div>
  );

  const spinner = (
    <ScaleLoader
      css={
        "display: flex; justify-content: center; align-items: center; flex-grow: 2"
      }
      size={2000}
      color={"#d32f2f"}
      loading={props.loading}
    />
  );

  const grid =
    notes.size === 0 ? (
      empty
    ) : (
      <div id="scrollable" className={classes.root}>
        <InfiniteScroll
          style={{ overflow: "hidden " }}
          className={classes.autoscroll}
          dataLength={notes.size}
          next={() => {
            onNextPage(props.term, props.filter, props.sort);
          }}
          hasMore={offset < total}
          loader={loadingSpinner}
          scrollableTarget="scrollable">
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ padding: 20 }}>
              <a style={{ textDecoration: "none" }} target="blank" href="https://www.tiktok.com/@b3atco">
              <Paper className={classes.banner}>
                <Icon color="secondary">tiktok</Icon>
                <Typography style={{ color: "white" }}>Follow us on Tiktok</Typography>
                <Icon color="secondary">tiktok</Icon>
              </Paper>
              </a>
            </Grid>
            {notes.map(note => (
              <Grid key={note.id} item xs={12} sm={6} md={4} lg={4} xl={3}>
                <AudioCard note={note} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </div>
    );

  return (
    <React.Fragment>
      {props.loading ? spinner : grid}
      {error && showError(error)}
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    loading: state.LatestStore.get("loading"),
    error: state.LatestStore.get("error"),
    notes: state.LatestStore.get("notes"),
    total: state.LatestStore.get("total"),
    offset: state.LatestStore.get("offset")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    search: (query, filter, sort) => dispatch(search(query, filter, sort)),
    nextPage: (query, filter, sort) => dispatch(nextPage(query, filter, sort)),
    clearError: () => dispatch(clearError())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Feed));
