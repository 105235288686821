import { createActions } from "redux-actions";
import { Howl } from "howler";

import { bumpUpPlayCount } from "../notes/actions";

const Types = {
  LOAD_NOTE: "LOAD_NOTE",
  PLAY_NOTE_START: "PLAY_NOTE_START",
  PLAY_NOTE_SUCCESS: "PLAY_NOTE_SUCCESS",
  PLAY_NOTE_ERROR: "PLAY_NOTE_ERROR",
  PAUSE_NOTE_SUCCESS: "PAUSE_NOTE_SUCCESS",
  PAUSE_NOTE_ERROR: "PAUSE_NOTE_ERROR",
  SEEK_NOTE_SUCCESS: "SEEK_NOTE_SUCCESS",
  SEEK_NOTE_ERROR: "SEEK_NOTE_ERROR",
  STOP_NOTE_SUCCESS: "STOP_NOTE_SUCCESS",
  STOP_NOTE_ERROR: "STOP_NOTE_ERROR",
  MUTE_NOTE_SUCCESS: "MUTE_NOTE_SUCCESS",
  MUTE_NOTE_ERROR: "MUTE_NOTE_ERROR"
};

const {
  loadNote,
  playNoteStart,
  playNoteSuccess,
  playNoteError,
  pauseNoteSuccess,
  pauseNoteError,
  seekNoteSuccess,
  seekNoteError,
  stopNoteSuccess,
  muteNoteSuccess
} = createActions(
  Types.LOAD_NOTE,
  Types.PLAY_NOTE_START,
  Types.PLAY_NOTE_SUCCESS,
  Types.PLAY_NOTE_ERROR,
  Types.PAUSE_NOTE_SUCCESS,
  Types.PAUSE_NOTE_ERROR,
  Types.SEEK_NOTE_SUCCESS,
  Types.SEEK_NOTE_ERROR,
  Types.STOP_NOTE_SUCCESS,
  Types.MUTE_NOTE_SUCCESS
);

const getSound = (note, getState, dispatch, callback) => {
  const sound = getState()
    .SoundsStore.get("sounds")
    .find(s => s.get("id") === note.get("id"));

  if (sound.get("sound")) {
    return callback(sound.set("sound", sound.get("sound")));
  }

  dispatch(bumpUpPlayCount(note.get("id")));
  const src = process.env.REACT_APP_BUCKET + note.get("path");

  const downloaded = new Howl({
    src: [src],
    html5: true,
    onend: () => dispatch(stopNoteSuccess(note.get("id"))),
    onload: () => {
      callback(sound.set("sound", downloaded));
    }
  });
};

const pauseNote = note => (dispatch, getState) => {
  const sound = getState()
    .SoundsStore.get("sounds")
    .find(s => s.get("id") === note.get("id"));

  if (sound.get("sound")) {
    sound.get("sound").pause();
    dispatch(pauseNoteSuccess(sound));
  }
};

const playNote = note => (dispatch, getState) => {
  dispatch(playNoteStart(note.get("id")));

  getSound(note, getState, dispatch, sound => {
    // Pause all running notes
    getState()
      .SoundsStore.get("sounds")
      .forEach(s => dispatch(pauseNote(s)));

    sound.get("sound").play();
    sound.get("sound").mute(sound.get("muted"));

    dispatch(playNoteSuccess(sound));
  });
};

const stopNote = note => (dispatch, getState) => {
  const sound = getState()
    .SoundsStore.get("sounds")
    .find(s => s.get("id") === note.get("id"));

  sound.get("sound").stop();
  dispatch(stopNoteSuccess(note.get("id")));
};

const seekNote = (note, position) => (dispatch, getState) => {
  const sound = getState()
    .SoundsStore.get("sounds")
    .find(s => s.get("id") === note.get("id"));

  if (sound.get("sound")) {
    sound.get("sound").seek(position);
    dispatch(seekNoteSuccess(sound));
  }
};

const muteNote = note => (dispatch, getState) => {
  const sound = getState()
    .SoundsStore.get("sounds")
    .find(s => s.get("id") === note.get("id"));

  if (sound.get("sound")) {
    sound.get("sound").mute(!sound.get("muted"));
  }

  dispatch(muteNoteSuccess(sound));
};

export {
  Types,
  loadNote,
  playNoteStart,
  playNoteSuccess,
  playNoteError,
  pauseNoteSuccess,
  pauseNoteError,
  seekNoteSuccess,
  seekNoteError,
  playNote,
  pauseNote,
  seekNote,
  stopNote,
  muteNote
};
