import React from "react";
import { withRouter } from "react-router-dom";

import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";

import styles from "./style";

function Tile(props) {
  const classes = styles();

  return (
    <Card
      onClick={() =>
        props.section.redirect
          ? window.open(props.section.href)
          : props.history.push(props.section.href)
      }
      className={classes.root}
      elevation={3}>
      <CardActionArea className={classes.action}>
        <Icon style={{ fontSize: 60 }} color="primary">
          {props.section.icon}
        </Icon>
        <Typography variant="subtitle2" className={classes.title}>
          {props.section.name}
        </Typography>
      </CardActionArea>
    </Card>
  );
}

export default withRouter(Tile);
