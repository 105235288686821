import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

function getFavorites() {
  const fav = localStorage.getItem("b3at-favorites");
  if (fav) {
    return Immutable.Set(JSON.parse(fav));
  }
  return Immutable.Set();
}

const INITIAL_STATE = Immutable.Map({
  favorites: getFavorites()
});

const reducer = handleActions(
  {
    [Types.ADD_FAVORITE]: (state, action) => {
      const favorites = state.get("favorites").add(action.payload);
      localStorage.setItem("b3at-favorites", JSON.stringify(favorites.toJS()));
      return state.updateIn(["favorites"], () => favorites);
    },

    [Types.REMOVE_FAVORITE]: (state, action) => {
      const favorites = state.get("favorites").delete(action.payload);
      localStorage.setItem("b3at-favorites", JSON.stringify(favorites.toJS()));
      return state.updateIn(["favorites"], () => favorites);
    }
  },
  INITIAL_STATE
);

export default reducer;
