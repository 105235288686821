import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Categories from "./categories";
import Banner from "../ads";

import { suggest } from "../../stores/suggestions/actions";

import styles from "./style";

function Explore(props) {
  const classes = styles();

  return (
    <div className={classes.root}> 
      <Banner/>
      <Categories />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loading: state.SuggestionsStore.get("loading"),
    error: state.SuggestionsStore.get("error"),
    suggestions: state.SuggestionsStore.get("suggestions")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    suggest: text => dispatch(suggest(text))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Explore));
