import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  parent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflowX: "hidden",
    overflowY: "scroll",
    marginTop: 20
  },
  root: {
    display: "flex",
    alignItems: "center"
  },
  comments: {
    padding: 10,
    margin: 5
  },
  error: {
    minWidth: 400,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },
  post: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    padding: 10
  },
  input: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  text: {
    marginRight: 20,
    backgroundColor: "white"
  }
}));

export default useStyles;
