import React from "react";
import { withSnackbar } from "notistack";

import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import LinkSharpIcon from "@material-ui/icons/LinkSharp";

import {
  EmailShareButton,
  EmailIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon
} from "react-share";

import styles from "./style";

function Social(props) {
  const classes = styles();

  const copy = function(str) {
    const el = document.createElement("textarea");
    el.value = str;
    document.getElementById("copy").appendChild(el);
    el.select();
    document.execCommand("copy");
    document.getElementById("copy").removeChild(el);

    props.enqueueSnackbar("Copied to clipboard", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center"
      }
    });
  };

  return (
    <Drawer anchor="bottom" open={props.open} onClose={props.onClose}>
      <div className={classes.root}>
        <div id="copy" className={classes.button}>
          <Fab onClick={() => copy(props.url)} color="primary" size="medium">
            <LinkSharpIcon />
          </Fab>
          <Typography variant="caption">Copy</Typography>
        </div>
        <EmailShareButton
          className={classes.button}
          url={props.url}
          title={props.name}>
          <EmailIcon size={48} round />
          <Typography variant="caption">Email</Typography>
        </EmailShareButton>
        <WhatsappShareButton
          className={classes.button}
          url={props.url}
          title={props.name}>
          <WhatsappIcon size={48} round />
          <Typography variant="caption">WhatsApp</Typography>
        </WhatsappShareButton>
        <FacebookShareButton
          className={classes.button}
          url={props.url}
          title={props.name}>
          <FacebookIcon size={48} round />
          <Typography variant="caption">Facebook</Typography>
        </FacebookShareButton>
        <TwitterShareButton
          className={classes.button}
          url={props.url}
          title={props.name}>
          <TwitterIcon size={48} round />
          <Typography variant="caption">Twitter</Typography>
        </TwitterShareButton>
        <RedditShareButton
          className={classes.button}
          url={props.url}
          title={props.name}>
          <RedditIcon size={48} round />
          <Typography variant="caption">Reddit</Typography>
        </RedditShareButton>
      </div>
    </Drawer>
  );
}

export default withSnackbar(Social);
