import React from "react";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Route, Switch, withRouter } from "react-router-dom";

import Home from "../home";
import Top from "../top";
import Newest from "../newest";
import Explore from "../explore";
import Category from "../category";
import Search from "../search";
import Favorites from "../favorites";
import Single from "../single";
import AppBar from "../appBar";
import Empty from "../empty";
import Contact from "../contact";

import styles from "./style";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#d32f2f",
      main: "#c62828",
      dark: "#b71c1c"
    },
    secondary: {
      light: "#424242",
      main: "#ffffff",
      dark: "#424242"
    },
    surface: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#ffffff"
    }
  },
  typography: {
    fontFamily: "'Noto Sans TC', sans-serif;"
  }
});

const App = function App(props) {
  const classes = styles();

  const appRouting = (
    <div className={classes.full}>
      <AppBar className={classes.appbar}>
        <div className={classes.app}>
          <Switch className={classes.app}>
            <Route path="/" exact component={Home} />
            <Route path="/explore" exact component={Explore} />
            <Route path="/top" exact component={Top} />
            <Route path="/newest" exact component={Newest} />
            <Route path="/category" exact component={Category} />
            <Route path="/search" exact component={Search} />
            <Route path="/favorites" exact component={Favorites} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/:id" exact component={Single} />
            <Route component={Empty} />
          </Switch>
        </div>
        {/*        {getIndex(props.location.pathname) !== -1 && navigation}
         */}{" "}
      </AppBar>
    </div>
  );

  return <MuiThemeProvider theme={theme}>{appRouting}</MuiThemeProvider>;
};

export default withRouter(App);
