import React from "react";

import Grid from "@material-ui/core/Grid";

import Tile from "../tile";

import { Sections } from "./constants";

import styles from "./style";

function Categories(props) {
  const classes = styles();

  const renderTile = key => {
    return (
      <Grid key={key} item xs={6} sm={6} md={4} lg={4} xl={3} spacing={3}>
        <Tile section={key} />
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container>{Sections.valueSeq().map(renderTile)}</Grid>
    </div>
  );
}

export default Categories;
