import { createActions } from "redux-actions";

import Elastic from "../../provider/elastic";

const Types = {
  SEARCH_START: "SEARCH_START",
  SEARCH_SUCCESS: "SEARCH_SUCCESS",
  SEARCH_ERROR: "SEARCH_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  NEXT_PAGE_START: "NEXT_PAGE_START",
  NEXT_PAGE_SUCCESS: "NEXT_PAGE_SUCCESS",
  NEXT_PAGE_ERROR: "NEXT_PAGE_ERROR",
  GET_IDS_START: "GET_IDS_START",
  GET_IDS_SUCCESS: "GET_IDS_SUCCESS",
  GET_IDS_ERROR: "GET_IDS_ERROR",
  ADD_COMMENT_START: "ADD_COMMENT_START",
  ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
  ADD_COMMENT_ERROR: "ADD_COMMENT_ERROR"
};

const {
  searchStart,
  searchSuccess,
  searchError,
  clearError,
  nextPageStart,
  nextPageSuccess,
  nextPageError,
  getIdsStart,
  getIdsSuccess,
  getIdsError,
  addCommentStart,
  addCommentSuccess,
  addCommentError
} = createActions(
  Types.SEARCH_START,
  Types.SEARCH_SUCCESS,
  Types.SEARCH_ERROR,
  Types.CLEAR_ERROR,
  Types.NEXT_PAGE_START,
  Types.NEXT_PAGE_SUCCESS,
  Types.NEXT_PAGE_ERROR,
  Types.GET_IDS_START,
  Types.GET_IDS_SUCCESS,
  Types.GET_IDS_ERROR,
  Types.ADD_COMMENT_START,
  Types.ADD_COMMENT_SUCCESS,
  Types.ADD_COMMENT_ERROR
);

const search = (q, filter, sort) => (dispatch, getState) => {
  dispatch(searchStart());
  const size = getState().LatestStore.get("size");
  const offset = 0;

  new Elastic().search(
    q,
    filter,
    sort,
    size,
    offset,
    (error, response, body) => {
      if (error || response.statusCode !== 200) {
        const message = "Oups. Something went wrong.";
        return dispatch(searchError(message));
      }
      return dispatch(searchSuccess(body));
    }
  );
};

const get = ids => dispatch => {
  dispatch(getIdsStart());
  new Elastic().get(ids, (error, response, body) => {
    if (error || response.statusCode !== 200) {
      const message = "Oups. Something went wrong.";
      return dispatch(getIdsError(message));
    }
    return dispatch(getIdsSuccess(body));
  });
};

const getNextPage = ids => dispatch => {
  dispatch(nextPageStart());

  new Elastic().get(ids, (error, response, body) => {
    if (error || response.statusCode !== 200) {
      const message = "Oups. Something went wrong.";
      return dispatch(nextPageError(message));
    }
    return dispatch(nextPageSuccess(body));
  });
};

const nextPage = (q, filter, sort) => (dispatch, getState) => {
  dispatch(nextPageStart());

  const offset = getState().LatestStore.get("offset");
  const size = getState().LatestStore.get("size");

  new Elastic().search(
    q,
    filter,
    sort,
    size,
    offset,
    (error, response, body) => {
      if (error || response.statusCode !== 200) {
        const message = "Oups. Something went wrong.";
        return dispatch(nextPageError(message));
      }
      return dispatch(nextPageSuccess(body));
    }
  );
};

const bumpUpPlayCount = id => () => {
  new Elastic().bumpUpPlayCount(id, (error, response) => {
    if (error || response.statusCode !== 200) {
      const message = `Could not bump up play count for id: ${id}`;
      console.log(message);
    }
  });
};

const addComment = (id, comment) => dispatch => {
  dispatch(addCommentStart());
  new Elastic().addComment(id, comment, (error, response) => {
    if (error || response.statusCode !== 200) {
      const message = "Oups. Something went wrong.";
      return dispatch(addCommentError(message));
    }
    return dispatch(
      addCommentSuccess({
        id,
        comment
      })
    );
  });
};

export {
  Types,
  search,
  get,
  getNextPage,
  nextPage,
  bumpUpPlayCount,
  clearError,
  addComment
};
