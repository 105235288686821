import { createActions } from "redux-actions";

import Elastic from "../../provider/elastic";

const Types = {
  KEYWORD_START: "KEYWORD_START",
  KEYWORD_SUCCESS: "KEYWORD_SUCCESS",
  KEYWORD_ERROR: "KEYWORD_ERROR"
};

const { keywordStart, keywordSuccess, keywordError } = createActions(
  Types.KEYWORD_START,
  Types.KEYWORD_SUCCESS,
  Types.KEYWORD_ERROR
);

const keywords = () => dispatch => {
  dispatch(keywordStart());

  new Elastic().getKeywords((error, response, body) => {
    if (error || response.statusCode !== 200) {
      const message =
        "We're unable to suggest any keywords. Something went wrong";
      return dispatch(keywordError(message));
    }
    return dispatch(keywordSuccess(JSON.parse(body).docs));
  });
};

export { Types, keywords };
