import { useEffect, useRef } from "react";
import React from "react";
export default function Banner() {
  const banner = useRef();

  // const atOptions = {
  //   key: "fcbc50593cddb6444bde3c15f562ea67",
  //   format: "iframe",
  //   height: 60,
  //   width: 468,
  //   params: {}
  // };
  // useEffect(() => {
  //   if (!banner.current.firstChild) {
  //     const conf = document.createElement("script");
  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.src = `//www.highperformancedformats.com/${atOptions.key}/invoke.js`;
  //     conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

  //     if (banner.current) {
  //       banner.current.append(conf);
  //       banner.current.append(script);
  //     }
  //   }
  // }, []);

  return (
    <div></div>
    // <div
    //   style={{
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center"
    //   }}
    //   ref={banner}></div>
  );
}
