import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: 20
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  controls: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  radioButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 12
  },
  control: {
    margin: 20,
    backgroundColor: "white"
  },
  playIcon: {
    color: "white"
  },
  seek: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20
  },
  slider: {
    padding: 0
  },
  times: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  time: {
    paddingTop: 10
  },
  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#ffebee"
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    paddingTop: 10
  },
  stats: {
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15
  },
  playCount: {
    display: "flex",
    justifyContent: "center"
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "85%",
    fontSize: "1.2rem",
    textAlign: "center",
    marginBottom: 10
  },
  comments: {
    margin: 20
  }
}));

export default useStyles;
