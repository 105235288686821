import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    display: "flex",
    justifyContent: "space-around"
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

export default useStyles;
