const useStyles = {
  root: {
    color: "#d32f2f",
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#c62828",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  seek: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20
  },
  slider: {
    padding: 0
  },
  times: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  time: {
    paddingTop: 10
  }
};

export default useStyles;
