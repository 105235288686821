import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  appbar: {
    display: "flex",
    flexDirection: "column"
  },
  app: {
    display: "flex",
    overflowX: "hidden",
    overflowY: "scroll",
    height: "100%"
  },
  navigation: {
    bottom: 0,
    width: "100%",
    backgroundColor: "#e0e0e0",
    paddingTop: 5,
    paddingBottom: 5
  },
  navigationAction: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  }
}));
