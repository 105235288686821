import request from "request";

const Elastic = function() {};

Elastic.prototype.search = function real(
  search,
  filter,
  sort,
  size,
  offset,
  callback
) {
  const s = sort && sort !== "" ? `${sort}:desc` : undefined;

  const query =
    !search || search === ""
      ? { match_all: {} }
      : [
          { match: { "title.exact": { query: search } } },
          { match: { title: { query: search } } },
          { match: { "title.ngram": { query: search } } },
          { match: { text: { query: search, fuzziness: 1 } } },
          { match: { text: { query: search } } },
          { match: { text: { query: search, operator: "AND" } } },
          { match_phrase: { text: { query: search } } }
        ];

  const q = {
    query: {
      bool: {
        should: query,
        filter: filter || undefined
      }
    }
  };

  const body = {
    index: "voice-note",
    from: offset,
    size,
    body: q,
    sort: s
  };

  const options = {
    uri: `${process.env.REACT_APP_API_ENDPOINT}/api/search`,
    method: "POST",
    json: body
  };

  request(options, callback);
};

Elastic.prototype.get = function get(ids, callback) {
  const options = {
    uri: `${process.env.REACT_APP_API_ENDPOINT}/api/get`,
    method: "POST",
    json: ids
  };

  request(options, callback);
};

Elastic.prototype.suggest = function suggest(text, callback) {
  const options = {
    uri: `${process.env.REACT_APP_API_ENDPOINT}/api/suggest?text=${text}`,
    method: "GET"
  };

  request(options, callback);
};

Elastic.prototype.bumpUpPlayCount = function bumpPlayCount(id, callback) {
  const options = {
    uri: `${process.env.REACT_APP_API_ENDPOINT}/api/update/${id}`,
    method: "POST",
    json: {
      script: {
        source: "ctx._source.playCount++"
      }
    }
  };

  request(options, callback);
};

Elastic.prototype.getKeywords = function getKeywordst(callback) {
  const options = {
    uri: `${process.env.REACT_APP_API_ENDPOINT}/api/keywords`
  };

  request(options, callback);
};

Elastic.prototype.addComment = function addComment(id, comment, callback) {
  const options = {
    uri: `${process.env.REACT_APP_API_ENDPOINT}/api/update/${id}`,
    method: "POST",
    json: {
      script: {
        source: "ctx._source.comments.addAll(params.comment)",
        lang: "painless",
        params: {
          comment: [comment]
        }
      }
    }
  };

  request(options, callback);
};

export default Elastic;
