import React from "react";

import moment from "moment";

import Feed from "../feed";

function Newest(props) {
  const filter = {
    range: {
      indexed_date: {
        gte: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD")
      }
    }
  };

  return <Feed term="" filter={filter} sort="indexed_date" />;
}

export default Newest;
