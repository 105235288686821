import Mock from "./mock";
import Real from "./real";

const Provider = function() {
  if (process.env.NODE_ENV === "development") {
    return new Mock();
  }
  return new Real();
};

export default Provider;
