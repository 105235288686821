import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import App from "./components/app";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

//Import redux stores
import LatestStore from "./stores/notes";
import SoundsStore from "./stores/sounds";
import SuggestionsStore from "./stores/suggestions";
import FavoritesStore from "./stores/favorites";
import KeywordsStore from "./stores/keywords";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const stores = createStore(
  combineReducers({
    LatestStore,
    SoundsStore,
    SuggestionsStore,
    FavoritesStore,
    KeywordsStore
  }),
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Router>
    <Provider store={stores}>
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={key => (
          <IconButton
            size="small"
            style={{ color: "white" }}
            onClick={onClickDismiss(key)}>
            <CloseIcon />
          </IconButton>
        )}>
        <App />
      </SnackbarProvider>
    </Provider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
