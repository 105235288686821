import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  error: null,
  loading: false,
  suggestions: Immutable.List()
});

const reducer = handleActions(
  {
    [Types.CLEAR_ERROR]: state => state.updateIn(["error"], () => null),

    [Types.SUGGEST_START]: state => state.updateIn(["loading"], () => true),

    [Types.SUGGEST_SUCCESS]: (state, action) =>
      state
        .updateIn(["loading"], () => false)
        .updateIn(["suggestions"], () => Immutable.fromJS(action.payload)),

    [Types.SUGGEST_ERROR]: (state, action) =>
      state
        .updateIn(["loading"], () => false)
        .updateIn(["suggestions"], () => Immutable.List())
        .updateIn(["error"], () => action.payload)
  },
  INITIAL_STATE
);

export default reducer;
