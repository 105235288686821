import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  error: null,
  loading: false,
  keywords: Immutable.List()
});

const reducer = handleActions(
  {
    [Types.KEYWORD_START]: state => state.updateIn(["loading"], () => true),

    [Types.KEYWORD_SUCCESS]: (state, action) =>
      state
        .updateIn(["loading"], () => false)
        .updateIn(["keywords"], () => Immutable.fromJS(action.payload)),

    [Types.KEYWORD_ERROR]: (state, action) =>
      state
        .updateIn(["loading"], () => false)
        .updateIn(["keywords"], () => Immutable.List())
        .updateIn(["error"], () => action.payload)
  },
  INITIAL_STATE
);

export default reducer;
