import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  error: null,
  loading: false,
  notes: Immutable.List(),
  offset: 0,
  size: 50,
  total: 0
});

const reducer = handleActions(
  {
    [Types.CLEAR_ERROR]: state => state.updateIn(["error"], () => null),

    [Types.SEARCH_START]: state =>
      state.updateIn(["loading"], () => true).updateIn(["offset"], () => 0),

    [Types.SEARCH_SUCCESS]: (state, action) =>
      state
        .updateIn(["loading"], () => false)
        .updateIn(["notes"], () => Immutable.fromJS(action.payload.docs))
        .updateIn(
          ["offset"],
          () => state.get("offset") + action.payload.docs.length
        )
        .updateIn(["total"], () => action.payload.total),

    [Types.SEARCH_ERROR]: (state, action) =>
      state
        .updateIn(["loading"], () => false)
        .updateIn(["notes"], () => Immutable.List())
        .updateIn(["error"], () => action.payload),

    [Types.GET_IDS_START]: state =>
      state.updateIn(["loading"], () => true).updateIn(["offset"], () => 0),

    [Types.GET_IDS_SUCCESS]: (state, action) =>
      state
        .updateIn(["loading"], () => false)
        .updateIn(["notes"], () => Immutable.fromJS(action.payload.docs))
        .updateIn(
          ["offset"],
          () => state.get("offset") + action.payload.docs.length
        )
        .updateIn(["total"], () => action.payload.total),

    [Types.GET_IDS_ERROR]: (state, action) =>
      state
        .updateIn(["loading"], () => false)
        .updateIn(["notes"], () => Immutable.List())
        .updateIn(["error"], () => action.payload),

    [Types.NEXT_PAGE_SUCCESS]: (state, action) =>
      state
        .updateIn(["notes"], () =>
          state.get("notes").concat(Immutable.fromJS(action.payload.docs))
        )
        .updateIn(
          ["offset"],
          () => state.get("offset") + action.payload.docs.length
        )
        .updateIn(["total"], () => action.payload.total),

    [Types.NEXT_PAGE_ERROR]: state =>
      state
        .updateIn(["notes"], () => Immutable.List())
        .updateIn(["offset"], () => 0),

    [Types.ADD_COMMENT_SUCCESS]: (state, action) => {
      const idx = state
        .get("notes")
        .findIndex(n => n.get("id") === action.payload.id);
      const note = state.get("notes").get(idx);
      const comments = note.get("comments") || Immutable.List();

      const updatedNote = note.set(
        "comments",
        comments.push(Immutable.fromJS(action.payload.comment))
      );
      const updatedNotes = state.get("notes").set(idx, updatedNote);

      return state.updateIn(["notes"], () => updatedNotes);
    }
  },
  INITIAL_STATE
);

export default reducer;
